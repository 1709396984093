<template>
  <Card
    card-class-names="tw-overflow-visible"
    :has-body-padding-small="true"
    :has-body-shadow="hasBodyShadow"
    :show-close-button="showCloseButton"
    @close-button-click="$emit('close-button-click')"
  >
    <template #body>
      <div :class="$style.container">
        <transition
          enter-active-class="fade-enter-transition"
          enter-from-class="fade-out"
          leave-active-class="fade-leave-transition"
          leave-to-class="fade-out"
        >
          <div
            v-if="showLoading"
            :class="loadingClass"
          >
            <LoadingIndicator />
          </div>
        </transition>
        <div>
          <h2 class="tw-mt-3 tw-mb-2 section-title heading-color">
            {{ headingText }}
          </h2>
          <ul class="tw-mb-1 tw-text-sm">
            <li>
              <span class="text-regular-color">Elevation</span>
              <strong>{{ elevation }}</strong>
            </li>
            <li>
              <span class="text-regular-color">Coorinates</span>
              <strong>{{ coordinates }}</strong>
            </li>
            <li>
              <span class="text-regular-color">Location</span>
              <strong>{{ stateAndCountry }}</strong>
            </li>
          </ul>
          <div>
            <Input
              v-model="name"
              :full-width="true"
              label="Name of Location"
              type="text"
            />
            <label class="tw-block tw-pb-2 tw-leading-normal">
              Type of Location
            </label>
            <Select
              v-model="typeId"
              class="tw-w-full"
              label="key"
              :options="options"
              :reduce="(option) => option.value"
            >
              <template #option="option">
                <span
                  :class="$style.option"
                  :style="getLocationTypeIconStyle(option.value)"
                />
                {{ option.key }}
              </template>
              <template #selected-option="option">
                <span
                  :class="$style.option"
                  :style="getLocationTypeIconStyle(option.value)"
                />
                {{ option.key }}
              </template>
            </Select>
          </div>
          <footer class="tw-my-4">
            <Button
              class="tw-w-full"
              :display-block="true"
              :type="buttonType"
              @click="handleSaveButtonClick"
            >
              Save
            </Button>
          </footer>
        </div>
      </div>
    </template>
  </Card>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import {
  addIsCustomToPath,
  getMaskImageStyle,
  formatElevation,
  isSavedLocation,
} from '@@/utils/CommonUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';

export default {
  name: 'SaveCustomLocationCard',

  props: {
    hasBodyShadow: {
      type: Boolean,
      default: false,
    },
    inDialog: {
      type: Boolean,
      default: true,
    },
    location: {
      type: Object,
      required: true,
    },
    showActions: {
      type: Boolean,
      default: false,
    },
    selectedLists: {
      type: Array,
      default: null,
    },
    showCloseButton: {
      type: Boolean,
      default: false,
    },
  },

  emits: ['close-button-click'],

  data() {
    return {
      name: this.location.name,
      showLoading: false,
      typeId: this.location.type_id,
    };
  },

  computed: {
    ...mapState(useMetaStore, { locationTypes: (state) => state.location_types }),
    ...mapState(useMetaStore, ['locationTypesById']),
    ...mapState(useUserStore, { units: (state) => state.preferences.units }),

    buttonType() {
      return ButtonTypes.primary;
    },

    coordinates() {
      const [lng, lat] = this.location.coordinates.point;
      return `${lat}, ${lng}`;
    },

    elevation() {
      return formatElevation(this.location.elevation, this.units);
    },

    headingText() {
      return this.isSavedLocation ? 'Edit Your Location' : 'Save Your Location';
    },

    loadingClass() {
      return [
        'tw-absolute tw-w-full tw-h-full tw-left-0 tw-top-0 tw-z-20',
        this.$style.loading,
      ];
    },

    isSavedLocation() {
      return isSavedLocation(this.location);
    },

    options() {
      return this.locationTypes.map((locationType) => ({
        key: locationType.name,
        value: locationType.id,
      }));
    },

    stateAndCountry() {
      const state = this.location.state_name;
      const country = this.location.country_name;

      if (state && country) {
        return `${state} • ${country}`;
      }

      return country;
    },
  },

  methods: {
    ...mapActions(useUserFavoritesStore, ['addFavoriteCustomLocation']),

    getLocationTypeIconStyle(typeId) {
      /* eslint camelcase: off */
      const { icon_url } = this.locationTypesById[typeId];

      return {
        backgroundColor: 'var(--text-darkest)',
        ...getMaskImageStyle(icon_url),
      };
    },

    async handleSaveButtonClick() {
      this.showLoading = true;

      try {
        const location = await this.addFavoriteCustomLocation({
          id: this.isSavedLocation ? this.location.id : null,
          list_ids: this.selectedLists?.length ? this.selectedLists : null,
          name: this.name,
          point: this.location.coordinates.point,
          type_id: this.typeId,
        });

        // Only navigate to the Location page if not already on the Location page!

        if (!this.$route.path.startsWith('/location')) {
          let path = `/location/${location.slug}/weather`;
          path = addIsCustomToPath(path, location);
          this.$router.push(path);
          return;
        }

        // Dispatch DOM custom event to inform parent that the custom location was updated or that
        // a new custom location was saved
        // SEE: https://stackoverflow.com/questions/42029150/how-to-bubble-events-on-a-component-subcomponent-chain-with-vue-js-2#answer-53785901

        const type = this.isSavedLocation ? 'update-custom-location' : 'save-custom-location';
        const options = { detail: { location }, bubbles: true, composed: true };
        this.$el.dispatchEvent(new CustomEvent(type, options));

        if (this.inDialog) {
          this.$emit('close-button-click');
        }
      }
      catch (exp) {
        const message = exp.response?.data?.messages?.[0] || 'Unable to save custom location.';
        this.$toast.open({ message, type: 'error' });
        this.showLoading = false;
      }
    },
  },
};
</script>

<style module>
/* stylelint-disable selector-class-pattern */

.loading {
  background-color: rgb(var(--card-background-rgb) / 50%);
}

.container {
  display: block;
}

.container :global(.vs__dropdown-menu) {
  --vs-dropdown-max-height: 105px;
}

@media (min-width: 992px) {
  .container :global(.vs__dropdown-menu) {
    --vs-dropdown-max-height: 250px;
  }
}

/*
 * NOTE: The option is rendered outside the #root element so we cannot use TailWind styles for it.
 */
.option {
  display: inline-block;
  height: 1.25rem;
  margin-right: 0.25rem;
  vertical-align: middle;
  width: 1.25rem;
}
</style>
