<template>
  <div>
    <div class="tw-flex tw-items-start tw-justify-end lg:tw-justify-evenly">
      <Button
        additional-class-names="tw-outline-none"
        :to="favoriteButtonUrl"
        :title="favoriteButtonTitle"
        :type="ButtonTypes.none"
        @click="handleFavoriteButtonClick"
      >
        <font-awesome-icon
          :class="iconClass"
          :icon="[
            isFavorite ? 'fas' : 'far',
            'star',
          ]"
        />
      </Button>
      <Button
        v-if="!isCustomLocation && isSavedLocation"
        additional-class-names="tw-ml-3 lg:tw-ml-4 tw-outline-none"
        :title="alertButton.title"
        :to="alertButton.to"
        :type="ButtonTypes.none"
        @click="handleAlertButtonClick"
      >
        <font-awesome-icon
          :class="iconClass"
          :icon="[
            hasAlert ? 'fas' : 'far',
            'bell',
          ]"
        />
      </Button>
      <Button
        v-else-if="isCustomLocation && isSavedLocation"
        class="tw-ml-3 lg:tw-ml-4 tw-outline-none"
        title="Edit Location"
        :type="ButtonTypes.none"
        @click="handleEditButtonClick"
      >
        <font-awesome-icon
          :class="iconClass"
          :icon="['far', 'edit']"
        />
      </Button>
    </div>
    <NotificationDialog
      v-bind="notificationDialogProps"
      :show-dialog="showNotificationDialog"
      @close="handleNotificationDialogClose"
    />
    <RemoveCustomLocationDialog
      :show-dialog="showConfirmDialog"
      @close="handleConfirmDialogClose"
    />
    <SelectFavoriteListDialog
      v-bind="selectFavoriteListDialogProps"
      :show-dialog="showSelectFavoriteListDialog"
      @close="handleSelectFavoriteListDialogClose"
      @continue="handleSelectFavoriteListDialogContinue"
    />
    <Dialog
      :show-dialog="showSaveDialog"
      @close="handleSaveDialogClose"
    >
      <SaveCustomLocationCard
        class="tw-w-full lg:tw-w-96"
        :has-body-shadow="true"
        :in-dialog="true"
        :location="location"
        :selected-lists="selectedLists"
        :show-close-button="true"
        @close-button-click="handleSaveDialogClose"
      />
    </Dialog>
  </div>
</template>

<script>
import { mapActions, mapState } from 'pinia';
import { ButtonTypes } from '@@/components/Common/Button.vue';
import { bannerTypes } from '@@/components/Common/Banner.vue';
import { getStartFreeTrialUrl } from '@@/utils/LoginUtils';
import { isSavedLocation } from '@@/utils/CommonUtils';
import { useUserStore } from '@@/stores/User';
import { useUserNotificationsStore } from '@@/stores/UserNotifications';
import { useUserFavoritesStore } from '@@/stores/UserFavorites';
import FavoritesStorageMixin from '@@/components/Favorites/FavoritesStorageMixin';

export default {
  name: 'SnowLocationActions',

  mixins: [FavoritesStorageMixin],

  props: {
    location: {
      type: Object,
      default: null,
    },
  },

  async setup() {
    const userNotificationStore = useUserNotificationsStore();
    await useAsyncData(() => userNotificationStore.fetchAlertAreas());
  },

  data() {
    return {
      notificationDialogProps: null,
      selectedLists: null,
      selectFavoriteListDialogProps: null,
      showNotificationDialog: false,
      showSaveDialog: false,
      showSelectFavoriteListDialog: false,
      showConfirmDialog: false,
    };
  },

  computed: {
    ...mapState(useUserStore, ['isAllAccess', 'isGuest']),
    ...mapState(useUserNotificationsStore, ['getAlertAreaByLocationId']),
    ...mapState(useUserFavoritesStore, ['isFavoriteLocation']),
    ...mapState(useUserFavoritesStore, { lists: (state) => state.user?.lists }),

    ButtonTypes() {
      return ButtonTypes;
    },

    alertArea() {
      if (this.isGuest) {
        return null;
      }

      return this.getAlertAreaByLocationId(this.location.id);
    },

    alertButton() {
      return {
        title: this.hasAlert ? 'Update or Remove Alert' : 'Add Alert',
        to: this.isGuest ? '/user/register' : null,
      };
    },

    canAddFavorite() {
      if (this.isGuest || (!this.isAllAccess && this.location.is_custom)) {
        return false;
      }

      return true;
    },

    favoriteButtonTitle() {
      return this.isFavorite ? 'Remove from Favorites' : 'Add to Favorites';
    },

    favoriteButtonUrl() {
      /* eslint camelcase: off */
      const return_to = this.$route.fullPath;
      let to = null;

      if (!this.canAddFavorite) {
        to = getStartFreeTrialUrl({
          isGuest: this.isGuest,
          query: { return_to },
        });
      }

      return to;
    },

    hasAlert() {
      return !!this.alertArea;
    },

    iconClass() {
      return 'tw-w-6 tw-h-6';
    },

    isCustomLocation() {
      return this.location?.is_custom === true;
    },

    isFavorite() {
      return this.isFavoriteLocation({ locationId: this.location.id });
    },

    isSavedLocation() {
      return isSavedLocation(this.location);
    },
  },

  methods: {
    ...mapActions(useUserStore, ['setBanner', 'fetchNotificationAlertAreas']),

    handleAlertButtonClick() {
      if (this.isGuest) {
        return;
      }

      this.notificationDialogProps = {
        action: this.hasAlert ? 'update' : 'create',
        alertArea: this.alertArea,
        location: this.location,
        view: 'alertAreas',
      };

      this.showNotificationDialog = true;
    },

    async handleConfirmDialogClose(canContinue) {
      this.showConfirmDialog = false;
      await this.$nextTick();

      if (canContinue) {
        this.handleUpdateFavorite(this.selectedLists);
      }
    },

    handleEditButtonClick() {
      this.selectedLists = this.getFavoriteListsForLocation(this.location);
      this.showSaveDialog = true;
    },

    async handleFavoriteButtonClick($event) {
      if (!this.canAddFavorite) {
        return;
      }

      const { originalEvent } = $event;
      originalEvent.preventDefault();
      originalEvent.stopImmediatePropagation();

      this.setUpShowFavoriteListDialog();

      originalEvent.target.blur();
    },

    handleLocationRemoved(isRemoved, selectedLists) {
      if (isRemoved && this.isCustomLocation) {
        // When a custom location is removed from the user's favorites then return to the
        // Favorites page because custom locations will be removed from the database once they're
        // no longer in any lists and all subsequent API calls to fetch forecasts or snow history
        // for the custom location will fail.

        const listName = selectedLists?.length === 1
          ? this.lists.find(({ id }) => id === selectedLists[0]).name
          : '';
        const message = `${this.location.name} was removed from your ${listName} favorites.`;

        const banner = {
          is_dismissable: true,
          message,
          type: bannerTypes.warning,
        };

        this.$router.push('/user/favorites');
        window.setTimeout(() => this.setBanner({ banner }), 750);
      }
    },

    handleSaveDialogClose() {
      this.showSaveDialog = false;
    },

    handleNotificationDialogClose() {
      this.showNotificationDialog = false;
      this.notificationDialogProps = null;
    },

    handleSelectFavoriteListDialogClose() {
      this.showSelectFavoriteListDialog = false;
    },

    async handleSelectFavoriteListDialogContinue(selectedLists) {
      this.showSelectFavoriteListDialog = false;

      if (this.isCustomLocation && !this.isFavorite) {
        this.selectedLists = selectedLists;
        this.showSelectFavoriteListDialog = false;
        this.showSaveDialog = true;
        return;
      }

      if (this.isCustomLocation && this.isFavorite && selectedLists.length === 0) {
        this.selectedLists = selectedLists;
        this.showConfirmDialog = true;
        return;
      }

      this.handleUpdateFavorite(selectedLists);
    },

    async handleUpdateFavorite(selectedLists) {
      const { location } = this;

      if (this.isFavorite) {
        const showToast = !(this.isCustomLocation && selectedLists.length === 0);
        const isRemoved = await this.updateFavorite({ location }, selectedLists, showToast);
        this.handleLocationRemoved(isRemoved, selectedLists);
      }
      else {
        const newFavoriteLocation = await this.addFavorite({ location }, selectedLists);

        if (!this.isSavedLocation) {
          const options = {
            detail: { location: newFavoriteLocation },
            bubbles: true,
            composed: true,
          };
          this.$el.dispatchEvent(new CustomEvent('save-custom-location', options));
        }
      }
    },

    setUpShowFavoriteListDialog() {
      const defaultSelectedLists = this.getFavoriteListsForLocation(this.location);

      this.selectFavoriteListDialogProps = {
        action: this.isFavorite ? 'update' : 'add',
        defaultSelectedLists,
        location: this.location,
      };

      this.showSelectFavoriteListDialog = true;
    },

    showToastMessage(action) {
      const type = action === 'added' ? 'success' : 'warning';
      const preposition = action === 'added' ? 'to' : 'from';
      const message = `${this.location.name} was ${action} ${preposition} your favorites.`;
      this.$toast.open({ type, message });
    },
  },
};
</script>
