<template>
  <header :class="headerClass">
    <div :class="detailsClass">
      <ResourceHeader
        v-if="isSkeleton"
        details-class="tw-hidden"
        image-class="tw-h-full skeleton-image"
        :image-url="imageUrl"
        :size="size"
      >
        <template #title>
          <span class="tw-inline-block tw-mb-1 skeleton-copy">{{ locationName }}</span>
        </template>
        <template #subtitle>
          <span class="skeleton-copy">{{ forecastPointElevation }} &bull; {{ state.name }} &bull; {{ country.name }}</span>
        </template>
      </ResourceHeader>
      <ResourceHeader
        v-else
        :image-url="imageUrl"
        :size="size"
      >
        <template #title>
          <NuxtLink
            v-if="!isPageHeader && !inMapCard"
            class="link-color-brand"
            :to="locationUrl"
          >
            {{ locationName }}
          </NuxtLink>
          <span
            v-else
            :class="locationNameClass"
          >
            {{ locationName }}
          </span>
        </template>
        <template
          v-if="!inMapCard && !isMyLocation"
          #subtitle
        >
          <NuxtLink
            v-if="!isPageHeader"
            class="hover:tw-underline link-color-brand"
            :to="mapUrl"
          >{{ forecastPointElevation }}</NuxtLink>
          <span v-if="!isPageHeader">&bull;</span>
          <NuxtLink
            class="link-color-brand"
            :to="stateUrl"
          >{{ state.name }}</NuxtLink>
          <span v-if="showSeparator">&bull;</span>
          <NuxtLink
            class="link-color-brand"
            :to="countryUrl"
          >{{ country.name }}</NuxtLink>
        </template>
        <template
          v-if="inMapCard || isPageHeader"
          #details
        >
          <strong class="tw-pr-1">
            <span
              v-if="locationTypeIconStyle"
              class="tw-inline-block tw-w-3.5 tw-h-3.5 tw-mr-1 tw-align-middle"
              :style="locationTypeIconStyle"
            />
            <font-awesome-icon
              v-else
              icon="map-marker-alt"
            />
            <span
              v-if="!isMyLocation"
              :class="inMapCard ? 'tw-hidden lg:tw-inline' : null"
            >
              Forecast Point
            </span>
          </strong>
          <Component
            :is="mapComponent"
            :class="isPageHeader ? 'hover:tw-underline link-color-brand' : null"
            :to="isPageHeader ? mapUrl : null"
          >
            {{ forecastPointDetails }}
          </Component>
        </template>
      </ResourceHeader>
    </div>
    <div
      v-if="showForecastAnywhereIcon"
      :class="forecastAnywhereIconClass"
    >
      <img
        class="tw-block tw-h-4 tw-w-4 lg:tw-h-5 lg:tw-w-5 dark-invert-100%"
        src="~/assets/img/ForecastAnywhere.png"
      >
    </div>
    <SnowLocationActions
      v-if="isPageHeader"
      :location="location"
    />
  </header>
</template>

<script>
/* eslint vue/multiline-html-element-content-newline: off */

import { mapState } from 'pinia';
import { addIsCustomToPath, formatElevation, getMaskImageStyle } from '@@/utils/CommonUtils';
import { addViewToComparePath, addViewToLocationPath } from '@@/components/Compare/Common/CompareUtils';
import { useMetaStore } from '@@/stores/Meta';
import { useUserStore } from '@@/stores/User';

export default {
  name: 'SnowLocationHeader',

  props: {
    compareField: {
      type: Object,
      default: null,
    },
    inMapCard: {
      type: Boolean,
      default: false,
    },
    isMyLocation: {
      type: Boolean,
      default: false,
    },
    isPageHeader: {
      type: Boolean,
      default: false,
    },
    isSkeleton: {
      type: Boolean,
      default: false,
    },
    location: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(useMetaStore, ['locationTypesById']),
    ...mapState(useUserStore, { units: (state) => state.preferences.units }),

    country() {
      return {
        countryCode: this.location.country_code,
        name: this.location.country_name,
      };
    },

    countryUrl() {
      const { compareField, currentPath } = this;
      const path = `/explore/countries/${this.country.countryCode}`;
      return addViewToComparePath(path, { compareField, currentPath });
    },

    currentPath() {
      return this.$route.path;
    },

    detailsClass() {
      return [
        'tw-flex',
        this.isPageHeader ? 'tw-order-last lg:tw-order-first' : '',
        this.inMapCard ? 'tw-mr-8' : '',
      ];
    },

    forecastAnywhereIconClass() {
      return [
        'tw-flex tw-w-5 tw-h-5 lg:tw-w-7 lg:tw-h-7 tw-rounded-sm tw-items-center tw-justify-center',
        this.$style.forecastAnywhereIcon,
      ];
    },

    forecastPointDetails() {
      if (this.isMyLocation) {
        return `${this.location.name} • ${this.forecastPointElevation}`;
      }

      const [lng, lat] = this.location.coordinates.point;
      return `${this.forecastPointElevation} • ${lat}, ${lng}`;
    },

    forecastPointElevation() {
      return formatElevation(this.location.elevation, this.units);
    },

    headerClass() {
      if (this.isPageHeader) {
        return 'tw-flex tw-flex-col lg:tw-flex-row lg:tw-justify-between';
      }

      if (this.inMapCard) {
        return 'tw-flex tw-pb-2 lg:tw-pb-3 tw-border-b border-color';
      }

      return 'tw-flex tw-justify-between';
    },

    imageUrl() {
      return this.isMyLocation ? null : this.location.image_url;
    },

    locationName() {
      return this.isMyLocation ? 'My Location' : this.location.name;
    },

    locationNameClass() {
      if (this.inMapCard) {
        return 'section-title heading-color';
      }

      if (this.isMyLocation) {
        return 'tw-inline-block tw-mb-1 lg:tw-mb-2';
      }

      return null;
    },

    locationTypeIconStyle() {
      /* eslint camelcase: off */
      const { type_id } = this.location;
      const { icon_url } = this.locationTypesById[type_id];

      if (!icon_url) {
        return null;
      }

      return {
        backgroundColor: 'var(--text-darkest)',
        ...getMaskImageStyle(icon_url),
      };
    },

    locationUrl() {
      const { compareField, currentPath } = this;
      const { slug } = this.location;
      const path = addViewToLocationPath(`/location/${slug}`, { compareField, currentPath });
      return addIsCustomToPath(path, this.location);
    },

    mapComponent() {
      return this.isPageHeader ? resolveComponent('NuxtLink') : 'span';
    },

    mapUrl() {
      return `/map#${this.location.slug}`;
    },

    showForecastAnywhereIcon() {
      return !this.isPageHeader && !this.inMapCard && this.location?.is_custom;
    },

    showSeparator() {
      return !!(this.state?.name && this.country?.name);
    },

    size() {
      if (this.isPageHeader) {
        return 'large';
      }

      if (this.inMapCard) {
        return 'medium';
      }

      return 'small';
    },

    skeletonImageClass() {
      return this.$style.skeletonImage;
    },

    state() {
      return {
        name: this.location.state_name,
        stateCode: this.location.state_code,
      };
    },

    stateUrl() {
      const { compareField, currentPath } = this;
      const path = `/explore/states/${this.state.stateCode}`;
      return addViewToComparePath(path, { compareField, currentPath });
    },
  },
};
</script>

<style module>
.forecastAnywhereIcon {
  background-color: var(--card-border);
}
</style>
